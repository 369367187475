<template>
  <b-overlay :show="is_busy" rounded="sm">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-2"></div>
        <div class="col-md-8">
          <div class="mb-2">
            <h2><strong>Pychomotor</strong></h2>
          </div>
          <div class="card">
            <div class="card-header">
              <div class="card-title"><h4>Select Details</h4></div>
            </div>
            <div class="card-body">
              <b-form @submit.prevent="updateUser()">
                <b-form-group class="">
                  <label>Select Class</label>
                  <select v-model="form.classes" class="form-control" required>
                    <option value=null> -- Select Class-- </option>
                    <option v-for="option in classes" :key="option.id" v-bind:value="option.id">
                      {{ option.name }}{{ option.form }}
                    </option>
                  </select>
                </b-form-group>

                <b-form-group class="">
                  <label>Select Session</label>
                  <select v-model="form.session" class="form-control" required>
                    <option value=null> -- Select Session-- </option>
                    <option v-for="option in sessions" :key="option.id" v-bind:value="option.id">
                      {{ option.name }}
                    </option>
                  </select>
                </b-form-group>
                  
                <b-form-group class="">
                  <label>Select Term</label>
                  <b-form-select v-model="form.term" :options="terms"></b-form-select>
                </b-form-group>
                <button type="submit" class="btn btn-success btn-lg btn-block">Submit</button>
              </b-form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import { Form } from 'vform';
import axios from 'axios';
  export default {
 
    data() {
      return {
        form: new Form({
          classes: null,
          session: null,
          term: null,
        }),
        sessions : [],
        subjects : [],
        classes : [],

        terms: [{ text: 'Select Term', value: null }, { text: 'First Term', value: 1 }, { text: 'Second Term', value: 2 }, { text: 'Third Term', value: 3 }],
        school: '',
        
        is_busy: false,
      }
    },

    created() {
      this.roleHelper(['1', '2', '5']);
      this.loadSettings();
    },

    methods: {
      loadSettings(){
        if(this.is_busy) return;
        this.is_busy = true;

        axios.get('/loaddata') 
        .then((response)=>{ 
          this.sessions = response.data.data.sessions;
          this.classes = response.data.data.classes;
        })
        .catch((err)=>{
          Swal.fire(
              "Error!",
              err.response.data.data.error,
              "error"
          );
          this.$router.back()
        })
        .finally(() => {
            this.is_busy = false;
        }); 
      },

      updateUser(){
        if(this.is_busy) return;
        this.is_busy = true;
        axios.post('/result/psychomotor', this.form)
        .then(()=>{
         this.$router.push({path: '/result/add/psychomotor/'+ this.form.classes + '/' + this.form.session +  '/' + this.form.term });
        })

        .catch(() => {
          this.$Progress.fail();
        })
        .finally(() => {
            this.is_busy = false;
        }); 
      },
    }, 
  }
</script>
