import { render, staticRenderFns } from "./Psychomotor.vue?vue&type=template&id=7b5567b7&"
import script from "./Psychomotor.vue?vue&type=script&lang=js&"
export * from "./Psychomotor.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports